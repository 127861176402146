<!-- 云平台报名缴费页面 -->
<template>
  <div id="payForRegistration">
    <div class="head">
      <!-- 顶部NavBar 开始 -->
      <van-nav-bar
        :title="$t('报名信息登记')"
      />
    </div>
    <div
      class="outboxstyle"
    >
      <van-form validate-trigger="onSubmit" :show-error-message="false" @submit="saveUserGetStandNameList">
        <div class="box-group">
          <div class="box-title">
            <van-icon name="manager-o" />
            <span class="box-title-text">学生信息</span>
          </div>
          <div class="boxstyle">
            <van-field
              v-model="form.idserial"
              type="text"
              label="身份证号："
              input-align="left"
              placeholder="请输入学生身份证号*"
              readonly
              :rules="[{ required: true, message: '请输入学生身份证号!' }]"
              @touchstart.stop="show = true,showPhone = false"
            />
            <van-number-keyboard
              :show="show"
              extra-key="X"
              close-button-text="完成"
              @blur="show = false"
              @input="onInput"
              @delete="onDelete"
            />
            <van-field
              v-model="form.name"
              type="text"
              maxlength="16"
              input-align="left"
              placeholder="请输入学生姓名*"
              :rules="[{ required: true, message: '请输入学生姓名!' }]"
            >
              <div
                slot="label"
                v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:'"
              />
            </van-field>
            <van-field
              readonly
              is-link
              clickable
              name="picker"
              :value="form.showSex"
              input-align="left"
              placeholder="请选择学生性别*"
              :rules="[{ required: true, message: '请选择学生性别!' }]"
              @click="sexShow = true"
            >
              <div
                slot="label"
                v-html="'性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别:'"
              />
            </van-field>
            <van-popup v-model="sexShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="sexList"
                @confirm="onConfirmSex"
                @cancel="sexShow = false"
              />
            </van-popup>
            <van-field
              readonly
              is-link
              clickable
              name="picker"
              :value="form.showAcademyMajorId"
              input-align="left"
              placeholder="请选择学生学部*"
              :rules="[{ required: true, message: '请选择学生学部!' }]"
              @click="academyMajorIdShow = true"
            >
              <div
                slot="label"
                v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部:'"
              />
            </van-field>
            <van-popup v-model="academyMajorIdShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="identityList"
                @confirm="onConfirmIdentity"
                @cancel="academyMajorIdShow = false"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              is-link
              name="picker"
              :value="form.showSchoolGrade"
              input-align="left"
              placeholder="请选择学生年级*"
              :rules="[{ required: true, message: '请选择学生年级!' }]"
              @click="schoolGradeShow = true"
            >
              <div
                slot="label"
                v-html="'年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级:'"
              />
            </van-field>
            <van-popup v-model="schoolGradeShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="schoolGradeList"
                @confirm="onConfirmGrade"
                @cancel="schoolGradeShow = false"
              />
            </van-popup>
          </div>
          <div style="font-size: 12px; color: #ee0a24; padding-top: 10px;">
            <span>*注意:报名缴费后会自动生成登录账号，密码是身份证后六位。</span>
          </div>
        </div>
        <div class="box-group">
          <div class="box-title">
            <van-icon name="friends-o" />
            <span class="box-title-text">家长信息</span>
          </div>
          <div class="boxstyle">
            <van-field
              v-model="form.comUserParent.parentUserName"
              type="text"
              maxlength="16"
              input-align="left"
              placeholder="请输入家长姓名*"
              :rules="[{ required: true, message: '请输入家长姓名!' }]"
            >
              <div
                slot="label"
                v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:'"
              />
            </van-field>
            <van-field
              readonly
              is-link
              clickable
              name="picker"
              :value="form.comUserParent.showRelationship"
              input-align="left"
              placeholder="请选择学生关系*"
              :rules="[{ required: true, message: '请输入家长与学生关系!' }]"
              @click="relationshipShow = true"
            >
              <div
                slot="label"
                v-html="'关&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系:'"
              />
            </van-field>
            <van-popup v-model="relationshipShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="relationshipList"
                @confirm="onConfirmRelationship"
                @cancel="relationshipShow = false"
              />
            </van-popup>
            <van-field
              v-model="form.comUserParent.parentPhone "
              type="text"
              label="手机号码："
              input-align="left"
              placeholder="请输入家长手机号码*"
              readonly
              :rules="[{ required: true, message: '请输入家长手机号码!' }]"
              @touchstart.stop="showPhone = true,show = false"
            />
            <van-number-keyboard
              :show="showPhone"
              close-button-text="完成"
              @blur="showPhone = false"
              @input="phoneOnInput"
              @delete="phoneOnDelete"
            />
            <van-field
              v-model="form.comUserParent.registered"
              is-link
              readonly
              label="户籍地址："
              placeholder="请选择户籍地址"
              @click="registeredSelect = true"
            />
            <van-popup v-model="registeredSelect" position="bottom">
              <van-area
                title="户籍地址："
                :area-list="registeredList"
                @confirm="onConfirmRegistered"
                @cancel="registeredSelect = false"
              />
            </van-popup>
            <van-field
              v-model="form.comUserParent.address"
              is-link
              readonly
              label="所在地区："
              placeholder="请选择所在地区"
              @click="addressSelect = true"
            />
            <van-popup v-model="addressSelect" position="bottom">
              <van-area
                title="所在地区"
                :area-list="addressList"
                @confirm="onConfirmAddress"
                @cancel="addressSelect = false"
              />
            </van-popup>
            <van-field
              v-model="form.comUserParent.detailedAddress"
              type="textarea"
              rows="1"
              autosize
              maxlength="50"
              label="详细住址："
              input-align="left"
              placeholder="请输入详细住址"
            />
            <van-field
              v-model="form.comUserParent.remarks"
              type="textarea"
              rows="1"
              autosize
              maxlength="50"
              input-align="left"
              placeholder="请输入备注"
            >
              <div
                slot="label"
                v-html="'备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:'"
              />
            </van-field></div>
        </div>
        <div class="bottom">
          <van-button size="large" class="bottombutton bottombutton-border" @click="register">已注册？去登录</van-button>
          <van-button type="info" size="large" class="bottombutton" native-type="submit" :loading="btnNextLoading">{{ $t('注册缴费') }}</van-button>
        </div>
      </van-form>
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import Cookies from 'js-cookie'
import { reportSaveUserAndParent } from '@/api/project'
import { idnum, phone } from '@/api/numberVerification'
import { getDictDatasByTypesNotToken } from '@/api/dict'
import { getDeptListNotToken } from '@/api/dept'
import { areaList } from '@vant/area-data'
import { NumberKeyboard } from 'vant'
import { reportGeneratearent } from '@/api/project'
import Vue from 'vue'
Vue.use(NumberKeyboard)
export default {
  name: 'YingXinQrcode',
  data() {
    return {
      show: false,
      showPhone: false,
      form: {
        idserial: '',
        name: '',
        sex: '',
        showSex: '',
        schoolCode: '',
        academyId: '',
        // 默认校内用户
        identityType: '100',
        academyMajorId: '',
        academyDepartmentId: '',
        showAcademyMajorId: '',
        schoolGrade: '',
        showSchoolGrade: '',
        dataSource: 'PAY',
        showStudentIdentity: '',
        comUserParent: {
          parentUserName: '',
          showRelationship: '',
          registered: '',
          parentPhone: '',
          address: '',
          detailedAddress: '',
          relationship: '',
          remarks: ''

        }
      },
      areaList,
      addressList: [],
      registeredList: [],
      academyMajorIdShow: false,
      schoolGradeShow: false,
      studentTypeShow: false,
      sexShow: false,
      relationshipShow: false,
      addressSelect: false,
      registeredSelect: false,
      btnNextLoading: false,
      identityList: [],
      schoolGradeList: [],
      schoolGrade_List: [],
      sexList: [],
      relationshipList: [],
      paymentList: [],
      studentList: []
    }
  },
  mounted() {
    // 获取url地址中的schoolCode
    window.localStorage.setItem('schoolCode', this.$route.query.schoolCode)
    // 户籍所在初始
    this.registeredList = JSON.parse(JSON.stringify(areaList))
    // 所在区域初始
    this.addressList = JSON.parse(JSON.stringify(areaList))
    // 获取字典表：性别，亲属关系
    this.getDictDatasByTypesNotToken()
    // 获取学部信息
    this.getDeptDepartmentList()
    // 获取年级信息
    this.getDeptGradeList()
  },
  methods: {
    // 已注册？去登录
    register() {
      // 通过push进行跳转
      this.$router.push({ path: '/quicklylogin' })
    },
    // 身份证号数字键盘输入
    onInput(value) {
      if (this.form.idserial.length < 18) {
        this.form.idserial += value
      }
    },
    // 身份证号数字键盘删除
    onDelete() {
      this.form.idserial = this.form.idserial.substring(0, this.form.idserial.length - 1)
    },
    // 手机号数字键盘输入
    phoneOnInput(value) {
      if (this.form.comUserParent.parentPhone.length < 11) {
        this.form.comUserParent.parentPhone += value
      }
    },
    // 手机号数字键盘删除
    phoneOnDelete() {
      this.form.comUserParent.parentPhone = this.form.comUserParent.parentPhone.substring(0, this.form.comUserParent.parentPhone.length - 1)
    },
    // 获取字典
    getDictDatasByTypesNotToken() {
      getDictDatasByTypesNotToken('SEX,RELATIONSHIP', this.$route.query.schoolCode, 'PAY').then(response => {
        const sexList = response.data.SEX
        for (let i = 0; i < sexList.length; i++) {
          this.$set(sexList[i], 'text', sexList[i].value)
        }
        this.sexList = sexList
        const relationshipList = response.data.RELATIONSHIP
        for (let i = 0; i < relationshipList.length; i++) {
          this.$set(relationshipList[i], 'text', relationshipList[i].value)
        }
        this.relationshipList = relationshipList
      })
    },
    // 部门二级级学部
    getDeptDepartmentList() {
      this.identityList = []
      getDeptListNotToken('DEPARTMENT', this.$route.query.schoolCode).then(response => {
        const identityList = response.data
        for (let i = 0; i < identityList.length; i++) {
          this.$set(identityList[i], 'text', identityList[i].deptName)
        }
        this.identityList = identityList
      })
    },
    // 部门三级年级
    getDeptGradeList() {
      this.schoolGrade_List = []
      getDeptListNotToken('MAJOR', this.$route.query.schoolCode).then(response => {
        const schoolGradeList = response.data
        for (let i = 0; i < schoolGradeList.length; i++) {
          this.$set(schoolGradeList[i], 'text', schoolGradeList[i].deptName)
          this.schoolGrade_List = schoolGradeList
        }
      })
    },
    // 字典表性别
    onConfirmSex(val) {
      this.form.showSex = val.value
      this.form.sex = val.key
      this.sexShow = false
    },
    // 选择学部（身份类别）
    onConfirmIdentity(val) {
      this.form.showAcademyMajorId = val.deptName
      this.form.academyDepartmentId = val.id
      this.form.academyId = val.parentId
      this.academyMajorIdShow = false
      this.schoolGradeList = []
      this.form.showSchoolGrade = ''
      this.form.academyMajorId = ''
      for (let i = 0; i < this.schoolGrade_List.length; i++) {
        if (val.id === this.schoolGrade_List[i].parentId) {
          this.schoolGradeList.push(this.schoolGrade_List[i])
        }
      }
    },
    // 选择年级
    onConfirmGrade(val) {
      this.form.showSchoolGrade = val.deptName
      this.form.academyMajorId = val.id
      this.schoolGradeShow = false
    },
    // 选择关系
    onConfirmRelationship(val) {
      this.form.comUserParent.showRelationship = val.value
      this.form.comUserParent.relationship = val.key
      this.relationshipShow = false
    },
    // 选择户籍所在地
    onConfirmRegistered(value) {
      this.form.comUserParent.registered = ''
      var list = JSON.parse(JSON.stringify(value))
      for (var i = 0; i < list.length; i++) {
        this.form.comUserParent.registered += list[i].name
      }
      this.registeredSelect = false
    },
    // 选择家庭住址
    onConfirmAddress(value) {
      this.form.comUserParent.address = ''
      var list = JSON.parse(JSON.stringify(value))
      for (var i = 0; i < list.length; i++) {
        this.form.comUserParent.address += list[i].name
      }
      this.addressSelect = false
    },
    // 下一步提交
    saveUserGetStandNameList() {
      if (!idnum(this.form.idserial)) {
        Toast.fail('身份证号格式不正确，请重新输入！')
        return
      }
      if (!phone(this.form.comUserParent.parentPhone)) {
        Toast.fail('手机号格式不正确，请重新输入！')
        return
      }
      Dialog.alert({
        title: '提示',
        message: '学生信息提交后无法修改，请谨慎填写！',
        showCancelButton: true
      }).then(() => {
        this.btnNextLoading = true
        this.form.schoolCode = this.$route.query.schoolCode
        reportSaveUserAndParent(this.form).then(response => {
          Cookies.set('datalook_reimbursement_token', response.data)
          this.$store.dispatch('user/refreshToken', response.data)
          window.localStorage.setItem('accountNum', this.form.idserial)
          Toast.fail(response.message)
          // 生成报名缴费
          reportGeneratearent(
            this.form.idserial,
            this.$route.query.schoolCode
          ).then(response => {
            this.$router.push({ path: '/paymentOnBehalf' })
            this.btnNextLoading = false
          }).catch(error => {
            Toast.fail(error.message)
            this.btnNextLoading = false
          })
        }).catch(error => {
          Toast.fail(error.message)
          this.btnNextLoading = false
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.red{
    .van-cell__value{
        color:red;
        span{
            color: red;
        }
    }
     .van-field__control--right{
        color:red
    }
}
#payForRegistration {
  position: relative;
  background-color: #eff6ff;
  height: 100vh;
  .head {
    height: 40px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000
    ;
  }
}
.van-nav-bar{
    background: none;
    height: 100%;
    font-size: 16px;
}
.outboxstyle {
  background-color: #F5F5F5;
  margin-bottom: 60px;
  width: 100%;
  padding: 60px 16px 80px 16px;
  box-sizing: border-box;
}
.boxstyle {
   // padding: 10px;
  margin: 0px 0px 0px 0px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(1, 1, 27, 0.02);
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  margin: 10px 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.lastbattry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: #1989fa;
    float: right;
  }
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 68px;
  z-index: 99;
  border-top: 1px solid #ebedf0;
  box-sizing: border-box;
  padding:12px 16px;
  display: flex;
  gap: 12px;
}
.bottombutton{
  width:100%;
  height: 100%;
  box-shadow: 0 0 10px rgb(4, 26, 73,0.1);
}
.bottombutton-border{
    border:1px solid #2F9FF2;
    box-shadow: none;
    color:#2F9FF2;
}
.van-cell__title {
    span {
      height: 44px;
      line-height: 44px;
    }
}
.boxstyle .van-cell {
    padding: 10px 10px;
    background:none;
}
.boxstylemoney {
  .van-cell {
      padding: 18px 20px !important;
  }
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
#payForRegistration .van-field__label {
    line-height: 24px !important;
    height: 24px !important;
    width: 2.2rem;
}
#payForRegistration .van-field__value {
    line-height: 24px !important;
    //height: 24px !important;
}
.text {
  color: #ffffff;
  font-size: 15px;
  font-family: PingFang SC;
  line-height: 14px;
}
.field_label {
    font-size: 16px;
    font-weight:100;
}
.box-title{
    font-size: 14px;
    margin-bottom: 6px;
    color: #2F9FF2;
}
.box-group{
    margin-bottom: 20px;
}
.box-title-text{
    vertical-align: top;
    margin-left: 4px;
    font-weight: 550;
}
</style>
