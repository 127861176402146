import request from '@/utils/request'
export function getDeptTree(dataSource, schoolCode) {
  return request({
    url: 'common/com/dept/getDeptTree/' + dataSource + '/' + schoolCode,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      return data
    }]
  })
}

export function saveDeptData(data) {
  return request({
    url: 'common/com/dept/saveDeptData',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function updateDeptData(data) {
  return request({
    url: 'common/com/dept/updateDeptData',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getDepartmentById(deptId) {
  return request({
    url: 'common/com/dept/' + deptId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 部门导入
export function userExcelImport(data, dataSource, schoolCode) {
  return request({
    url: 'common/com/dept/deptImport/' + dataSource + '/' + schoolCode,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
export function deleteDeptData(deptId, createby) {
  return request({
    url: 'common/com/dept/deleteDeptData/' + deptId,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getComUsers(data) {
  return request({
    url: 'common/com/user/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getDeptListNotToken(deptType, schoolCode) {
  return request({
    url: 'common/com/dept/getDeptListNotToken',
    method: 'post',
    params: {
      'deptType': deptType,
      'schoolCode': schoolCode

    }
  })
}

